import sidebarData from "./sidebarData";
import logo from "../../assets/logo/logo.svg";
import { useNavigate } from "react-router-dom";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import Menu from "./Menu";
import { resetLogin } from "../../redux/slices/userSlice";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(resetLogin());
  };

  return (
    <div
      className="sidebar bg-primaryColor w-64 min-h-full border-r border-gray-200"
      id="sidebar"
    >
      <a
        href="https://www.meropadhai.com"
        target="_blank"
        className="flex justify-center pt-[50px] px-6 py-2 scroll"
      >
        <img
          src={logo}
          alt="logo"
          onClick={() => {
            navigate("/");
          }}
        />
      </a>
      <div className="sidebar flex flex-col" id="sidebar">
        <div className="pt-6 text-white h-[calc(100vh-180px)] overflow-y-auto pr-[10px]">
          <div className=" flex flex-col px-4 py-3 space-y-2">
            {sidebarData.map((sidebar) => {
              return (
                // <Link key={sidebar.id} to={sidebar.link}>
                //   <div
                //     className={`hover:bg-hoverColor px-3 py-2 rounded-[5px] font-medium cursor-pointer ${
                //       location.pathname.includes(sidebar.link) &&
                //       "bg-hoverColor px-3 py-2 "
                //     }`}
                //   >
                //     <p>{sidebar.name}</p>
                //   </div>
                // </Link>
                <Menu key={sidebar.id} item={sidebar} />
              );
            })}
          </div>
        </div>
        <div className="text-primaryColor flex justify-between px-3 py-1 pr-6">
          <div
            onClick={() => handleLogout()}
            className="flex items-center w-full space-x-3 cursor-pointer font-medium bg-[#FFE5E7] py-3 px-6  text-[#FF000D] rounded-lg"
          >
            <ArrowRightOnRectangleIcon className="h-5 rotate-180" />
            <p>Logout</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
