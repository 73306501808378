import {
  ArrowLongRightIcon,
  CheckIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useState } from "react";
import postLoginApi from "../../services/auth/postLoginApi";
import { useDispatch } from "react-redux";
import { setLogin } from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";

import meropadhaiLogo from "../../assets/logo/code-it.svg";
import affiliateLoginImg from "../../assets/illustration/affiliateLoginImg.png";
import { toast } from "react-hot-toast";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isKeepLoggedIn, setIsKeepLoggedIn] = useState(false);

  const handleFormSubmit = async (e) => {
    try {
      e.preventDefault();

      const response = await postLoginApi({ data: { email, password } });

      console.log("resonse", response);
      if (!response?.isAdmin) {
        return toast.error("Please enter the admin login info.");
      }
      if (response) {
        dispatch(
          setLogin({
            token: response?.token,
            userData: { email: response?.email, name: response?.name },
            isRememberMe: isKeepLoggedIn,
          })
        );
      }
      navigate("/");
    } catch (e) {
      if (e?.response?.data?.validationErrors) {
        Object.entries(e?.response?.data?.validationErrors).map((obj) => {
          const value = obj[1];
          toast.error(value[0]);
          return;
        });
      } else {
        toast.error(e?.response?.data?.message);
      }
    }
  };

  return (
    <div className="flex h-[100vh] ">
      <div className="basis-1/2 min-w-[560px]">
        <a href="http://www.meropadhai.com/" target="_blank">
          <img
            src={meropadhaiLogo}
            className="text-blue mx-20 my-8"
            width="160px"
          />
        </a>
        <form
          className="flex flex-col justify-center items-center  min-h-[calc(100vh-112px)]"
          onSubmit={(e) => handleFormSubmit(e)}
        >
          <h4 className="font-semibold text-[32px]">
            <span className="text-gray-800">Login to</span>
            <span className="text-primaryColor"> Admin Portal</span>
          </h4>

          <div className="mt-[56px] text-gray-600">
            <div className="">
              <div className="text-sm">Email</div>
              <input
                type="text"
                required={true}
                className="min-w-[480px] mt-2 p-4 border border-gray-300 bg-white rounded-lg outline-none"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e?.target?.value)}
              />
            </div>
            <div className=" mt-4">
              <div className="text-sm">Password</div>
              <div className="flex min-w-[480px] mt-2 p-4 border border-gray-300 rounded-lg ">
                <input
                  type={showPassword ? "text" : "password"}
                  required={true}
                  className="w-full bg-white outline-none"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e?.target?.value)}
                />
                {showPassword ? (
                  <EyeSlashIcon
                    className="h-6 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <EyeIcon
                    className="h-6 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </div>
            </div>

            <div className=" flex items-center mt-4">
              {/* Checkbox */}
              <div
                className=""
                onClick={() => setIsKeepLoggedIn((prev) => !prev)}
              >
                {isKeepLoggedIn ? (
                  <div className="bg-primaryColor w-5 h-5 rounded">
                    <CheckIcon className="h-full text-white" />
                  </div>
                ) : (
                  <div className="w-5 h-5 border-2 border-gray-300 rounded"></div>
                )}
              </div>

              <span className=" ml-2 text-sm">Keep me logged in</span>
            </div>

            <button
              type="submit"
              className="flex w-full gap-3 mt-[56px] primaryButton justify-center cursor-pointer"
            >
              <span>Login </span>
              <ArrowLongRightIcon className="h-6" />
            </button>
          </div>
        </form>
      </div>
      <div className="basis-1/2 bg-gray-50 flex items-center justify-center border border-gray-300">
        <img src={affiliateLoginImg} />
      </div>
    </div>
  );
};

export default Login;
